import './AuditDetails.css';
import DataTableBase from '../../ui/DataTableBase.js';
import Backdrop from '../../ui/Backdrop.js';
import ProjectsModal from '../../elements/dashboardElements/ProjectsModal.js';
import AddProjectsModal from '../../elements/dashboardElements/AddProjectsModal.js';
import PayrollModal from '../../elements/dashboardElements/PayrollModal.js'
import { RiRadioButtonLine } from 'react-icons/ri';
import {post,get, postBody} from "../../api/apiCalls";
import React, { useState,useEffect,useMemo} from 'react';
import SelectDropdown from "../../ui/SelectDropdown";
import moment from 'moment';
import DateRangeSelector from "../../ui/DateRangeSelector";
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";

function AuditDetails(props) {
 
  let [from, setFrom] = useState(moment().startOf('day').utc().format());
  let [to, setTo] = useState(moment().utc().add(1, 'days').format());
  let [projects,setProjects] = useState([]);
  let [loading,setLoading] = useState(true);

  const columns = [
    {
      id: 'Id',
      name: 'Id',
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'Level',
      name: 'Level',
      selector: (row) => row.level,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'Message',
      name: 'Log Details',
      selector: (row) => <div className='cell'><div className="cell-overflow">{row.message}</div></div>,
      sortable: true,
      reorder: true,
      wrap: true,
      
    },
    {
      id: 'TimeStamp',
      name: 'TimeStamp',
      selector: (row) => row.TimeStamp,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    // {
    //   id: 'MessageTemplate',
    //   name: 'Message Template',
    //   selector: (row) => row.messageTemplate,
    //   sortable: true,
    //   reorder: true,
      
    // },
    
  ];

  const setFromDate = (date) => {
    setFrom(date);
    from = date;
    console.log(moment(date).format("YYYY-MM-DD"));
    getAuditLog();
  };

  const setToDate = (date) => {
    setTo(date);
    to=date;
    console.log(moment(date).format("YYYY-MM-DD"));
    getAuditLog();
  };
  
  const getAuditLog = async() => {
    setLoading(true);
    let keys = {
      search : "aa",
      startTime : moment(from).utc().format(),
      endTime : moment(to).format("YYYY-MM-DD 23:59:59")
    }
    try {
    let auditResult = await get("Logger/Search?"+new URLSearchParams(keys).toString());
    let audits = [];
    for(let i = 0 ; i < auditResult.length ; i++){
      console.log(auditResult[i]);
      let auditEntity = auditResult[i];
      let audit = {
      id : auditEntity['Id'],
      message : auditEntity['Message'],
      messageTemplate : auditEntity['MessageTemplate'],
      level : auditEntity['Level'],
      TimeStamp : auditEntity['TimeStamp'],
      // project: projectEntity['Name'],
      // contact: projectEntity['ContactName'],

      // shared: projectEntity['SharedService'],
      // hours: projectEntity['HourCap'],
      // members : [{
      //   id: 1055,
      //   agentname: 'Jane Doe',
      // }]
      }
      audits.push(audit);
    }
      setProjects(audits);
      setLoading(false);
    }catch (error){

    }
    
  }

  useMemo(() => {
    getAuditLog();
  },[]);

  return (
    
    <div className='dashboardProjectsMain'>
       
      <div className='dashboardProjectsMain-header'>Audit Log</div>
      <DateRangeSelector
                    onFromChangeDate = {setFromDate}
                    onToChangeDate = {setToDate}  />
      {(() => {
              if (!loading){
                  return (
                    <div>

                    <DataTableBase
                      columns={columns}
                      data={projects}
                      tableName='ProjectsReport'
                      showDownloadButton={false}
                    />
                   
                    </div>
                  )
              }else {
                return <LoadingSpinner/>
              }
      })()}
   
    </div>
  );
}

export default AuditDetails;
