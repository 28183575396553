import React, { useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import "./SummaryChartCard.css";

ChartJS.register(ArcElement, Tooltip, Legend);

function SummaryChartCard(props) {

  
  const chartdata = {
    labels: ["Tracked Time", "Idle Time", "Manual Time"],
    datasets: [
      {
        data: [props.TotalTime, props.Idle, props.Manual],
        backgroundColor: ["#004225", "#B0BEC5", "#5669E1"],
        borderColor: ["#004225", "#B0BEC5", "#5669E1"],
        borderWidth: 1,
      },
    ],
  };

  const chartoptions = {
    responsive: true,
    cutout: "80%",
    radius: 80,
    plugins: {
      legend: {
        position: "right",
        display: true,
        fontSize: 16,
      },
    },
  };

  return (
    <div className="summaryChartCard">
      <div className="summaryChartTitle">{props.chartTitle}</div>
      <div className="summaryChart">
        <Doughnut data={chartdata} options={chartoptions}  
        />
      </div>
    </div>
  );
}

export default SummaryChartCard;
